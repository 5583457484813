<template>
  <div>
    <div class="text-center mb-2">
      <el-radio-group v-model="field.is_global_variable">
        <el-radio-button :label="false">Custom Field</el-radio-button>
        <el-radio-button :label="true">Global Variable</el-radio-button>
      </el-radio-group>
    </div>
    <el-form label-position="right" v-if="!field.is_global_variable">
      <title-and-description :field="field" />
      <el-row type="flex" :gutter="30">
        <el-col :span="12">
          <field-filled-by :field="field" />
        </el-col>
      </el-row>
      <el-row  :gutter="30">
        <el-col :span="4">
          <div class="form-group">
            <el-form-item label="Min Selection">
              <el-input-number
                v-model="field.min_selection"
                :controls="'false'"
              ></el-input-number>
            </el-form-item>
          </div>
        </el-col>
         <el-col :span="4">
          <div class="form-group">
            <el-form-item label="Max Selection">
              <el-input-number
                v-model="field.max_selection"
                :controls="'false'"
              ></el-input-number>
            </el-form-item>
          </div>
        </el-col>
        <el-col :span="6">
            <label>Field Options</label>
            <el-select
              placeholder="Enter options"
              v-model="field.options"
              :clearable="true"
              :allow-create="true"
              :default-first-option="true"
              :filterable="true"
              :multiple="true"
              :no-data-text="''"
            >
              <el-option
                v-for="(opt, index) of field.options"
                :key="index"
                :label="opt"
                :value="opt"
              >
              </el-option>
            </el-select>
            <i class="el-icon-edit" @click="openOptions"></i> 
        </el-col>
        <el-col :span="8">
          <label>Custom Error Notification</label>
          <el-input
            type="textarea"
            :rows="3"
            placeholder="Please enter a custom notification message."
            v-model="field.default_error_message"
          >
        </el-input>
      </el-col>
        <!-- <el-col :span="12">
          <is-field-required :field="field" class="field-required" />
        </el-col> -->
      </el-row>
      <el-row>
        <el-col :span="4">
          <div class="form-group">
            <el-form-item>
              <el-checkbox
                v-model="field.show_checkbox_label"
                :controls="false"
              > Show Label</el-checkbox>
            </el-form-item>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="form-group">
            <el-form-item>
              <el-switch
                v-model="field.view_type"
                active-text="Vertical View"
                inactive-text="Horizontal View">
              </el-switch>
            </el-form-item>
          </div>
        </el-col>
      </el-row>
      
    </el-form>
    <el-form label-position="right" v-if="field.is_global_variable">
      <el-row type="flex" :gutter="30">
        <el-col :span="12">
          <div class="form-group">
          
            <el-form-item label="Global Variable">
              <el-select v-model="field.global_variable_id" @change="setGlobalVariable">
                <el-option
                  v-for="(globalVariable,index) of allGlobalVariables"
                  :key="index"
                  :label="globalVariable.label"
                  filterable
                  :value="globalVariable._id"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-col>
      </el-row>
      <el-row type="flex" :gutter="30">
        <el-col :span="12">
        </el-col>
        <el-col :span="12">
          <field-filled-by :field="field" />
        </el-col>
      </el-row>
      <is-field-required :field="field" class="field-required"/>
    </el-form>
    <dialog-component
        :visible="optionsDialogVisible"
          :title="'Edit options'"
          :destroy-on-close="true"
          :containerWidth="'35%'"
          @before-close="closeDialog"
          center>
        <div class="tableScroll">
          <el-table  
           border
          :data="dialogOptions"
          @cell-mouse-enter="editoptionsData" 
          @cell-mouse-leave="resetoptionsData" 
          >
          <el-table-column  label="Options">
            <template slot-scope="scope">
              <div v-if="currentActiveRowIndex == scope.$index">
                <el-input size="mini" v-model="dialogOptions[currentActiveRowIndex]"></el-input>
              </div>
              <div v-else>
                <p style="font-size: 14px" slot="reference">
                {{ scope.row }}
              </p>  
            </div>
            </template>  
          </el-table-column>
          </el-table>

          
        </div>
          <el-row>
             <el-button style="background-color: #409eff;float:right; color: #ffffff" class="type-2" 
              @click="saveData">Save</el-button>
          </el-row>
    </dialog-component>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: {
    TitleAndDescription: () => import("./TitleAndDescription"),
     IsFieldRequired: () => import("./IsFieldRequired"),
    FieldFilledBy: () => import("./FieldFilledBy"),
  },
  name: "templates-formComponents-CheckboxGroup",
  props: ["field","isView"],
  data() {
    return {
      optionsDialogVisible:false,
      validations: [],
      currentActiveRowIndex: -1,
      dialogOptions: [],
      rowsOptions: [
        {
          title: "Folder 1",
          _id: "1",
        },
        {
          title: "Folder 2",
          _id: "2",
        },
        {
          title: "Folder 3",
          _id: "3",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("globalVariables", ["getAllGlobalVariables"]),
    allGlobalVariables() {
      return this.getAllGlobalVariables
        ? this.getAllGlobalVariables.data || []
        : [];
    },
  },
  mounted() {
    this.fetchGlobalVaribales();
  },
  methods: {
    async fetchGlobalVaribales() {
     
     let params = {
       get_all: true,
       input_type: this.field.input_type
     };

     await this.$store.dispatch(
       "globalVariables/fetchGlobalVariables",
       params
     );
   },
   setGlobalVariable() {
     let globalVariable = this.allGlobalVariables.find(
       x => x._id == this.field.global_variable_id
     );
     this.field.label = globalVariable.label;
     this.field.options = globalVariable.options;
   },
 
    closeDialog() {
      this.optionsDialogVisible = false; 
    },    
    openOptions() {
      if (this.isView) {
        return;
      }
      this.optionsDialogVisible = true;
    },
    changeField() {
      this.$set(this.form, this.field.key, !this.form[this.field.key]);
    },
    editoptionsData(row) {
      this.currentActiveRowIndex = this.field.options.indexOf(row);
    },
    resetoptionsData() {
      const uniqueOptions = [...new Set(this.dialogOptions)];
      if (uniqueOptions.length < this.dialogOptions.length) {
        this.dialogOptions = uniqueOptions;
      }
      this.field.options = this.dialogOptions.slice();
      this.currentActiveRowIndex = -1;
    },
    saveData(){
      this.optionsDialogVisible = false;
    }
  },
  watch: {
    optionsDialogVisible(newVal) {
      if (newVal) {
        this.dialogOptions = this.field.options.slice();
      }
    },
  },
  
};
</script>

<style  lang="scss">

.el-select .el-select__tags {
    display: flex;
    overflow-x: hidden;
    flex-wrap: nowrap;
}
.tableScroll {
  height: 50vh;
  overflow-y: scroll;
  overflow-x: scroll;
  border-top: 1px ridge;
  border-left: 1px ridge;
  border-bottom: 1px ridge;
  margin-right: 5px;
  width:100%
}
.tableScroll::-webkit-scrollbar {
  width: 0.5em;
}
.tableScroll::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  background: #e1e1e1;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}
.el-col-6 {
    width: 25%;
    line-height: 2.02rem;
}
</style>
